import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('myDiv', { static: true }) myDiv: ElementRef;

  accounts = [];
  top10 = [];
  accountsCounter: number = 0;
  gamesCounter: number = 0;
  nationCounter: number = 0;
  nationalities = []
  showLeftArrow = false
  showRightArrow = true
  flags = []

  constructor(
    private dataService: DataService
  ) { }



  ngOnInit() {
    this.dataService.getAccounts().subscribe(
      accounts => {
        for (let account of accounts) {
          this.top10 = accounts.filter(player => player.playedGames > 4)
          this.accounts = accounts
          if (account.nationality.id && this.nationalities.indexOf(account.nationality.id) === -1) {
            this.nationalities.push(account.nationality.id)
            this.flags.push(account.nationality.iso2)
          }
        }
        this.animate()
      })
    this.dataService.getGames().subscribe(
      res => {
        let gamesCounterStop: any = setInterval(() => {
          if (this.gamesCounter < res.value) {
            this.gamesCounter++;
          }
        }, 5)
      })
  }


  animate() {
    let accountsCounterStop: any = setInterval(() => {
      if (this.accountsCounter < this.accounts.length) {
        this.accountsCounter++;
      }
    }, 15)
    let nationCounterStop: any = setInterval(() => {
      if (this.nationCounter < this.nationalities.length) {
        this.nationCounter++;
      }
    }, 333)
  }

  checker() {
    setTimeout(() => {
      let last = this.myDiv.nativeElement.children.length
      if (this.myDiv.nativeElement.children[0].classList[1] == 'active') {
        // if first slide
        this.showLeftArrow = false
        this.showRightArrow = true
      } else if (this.myDiv.nativeElement.children[last - 1].classList[1] == 'active') {
        //Last Slide
        this.showLeftArrow = true
        this.showRightArrow = false
      } else {
        this.showLeftArrow = true
        this.showRightArrow = true
      }
    }, 700);
  }
}
