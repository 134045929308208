import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    private http: HttpClient

  ) { }

  public getAccounts() {
    return this.http.get<any[]>((`https://server2.dartsleague.io/accounts?all=true`));
  }

  public getGames() {
    return this.http.get<any>((`https://server2.dartsleague.io/v1/games/statistic`));
  }
}