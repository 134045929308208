import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IndividualConfig } from 'ngx-toastr';
import { EmailService } from '../email.service';
import { CommonService, toastPayload } from '../services/common.service';

@Component({
  selector: 'app-pokemon',
  templateUrl: './pokemon.component.html',
  styleUrls: ['./pokemon.component.scss']
})
export class PokemonComponent implements OnInit {
  toast!: toastPayload;
  players: any[] = []
  counter = 0
  alreadySent = 'false'
  started = 'false'
  userArray = [{
    'key': 'hgi', 'status': false, 'username': 'DieAmisRedenTuerkiSh', 'id': '61aaa77ba38fc50016775622'
  },
  {
    'key': 'bnz', 'status': false, 'username': 'DosBenzos', 'id': '5fedc8e3eaf9ec00177acd15'
  },
  {
    'key': 'sio', 'status': false, 'username': 'Al Solo', 'id': '61854274bb21c3001606de2d'
  },
  {
    'key': 'gls', 'status': false, 'username': 'glaus', 'id': '61b51c85e740410016fee4b8'
  },
  {
    'key': 'kai', 'status': false, 'username': 'kw90', 'id': '63755d41bb2b35ba7f0c4e2d'
  },
  {
    'key': 'bnj', 'status': false, 'username': 'benjaminE', 'id': '63754311aa0d13a0b52a9b9e'
  }]

  //get user out of querystring
  user = document.location.search.split('=')[1];
  //count catches
  count = 0;
  //set status to show button
  status = false;

  FormData: UntypedFormGroup;
  constructor(
    private cs: CommonService,
    // private toaster: Toaster,
    private builder: UntypedFormBuilder,
    private emailService: EmailService,
    private router: Router) { }

  ngOnInit() {
    this.FormData = this.builder.group({
      EmailAddress: new UntypedFormControl('', [Validators.compose([Validators.required, Validators.email])])
    })

    //check if localStorage is already set
    if (localStorage.getItem('userList') !== null) {
      this.checkUser();
    }
    //setting localStorage Item if not existing
    else {
      localStorage.setItem('userList', JSON.stringify(this.userArray))
      localStorage.setItem('status', 'false')
      localStorage.setItem('started', 'false')
      this.checkUser();
    }
  }

  onSubmit(FormData) {
    this.emailService.SendEmail(FormData)
      .subscribe(response => {
        localStorage.setItem('status', 'true')
        this.alreadySent = 'true'
        this.toast = {
          message: 'Email successfully submitted!',
          title: 'Success!',
          type: 'success',
          ic: {
            timeOut: 2500,
            closeButton: true,
          } as IndividualConfig,
        };
        this.cs.showToast(this.toast);
      }, error => {
        console.warn(error.responseText)
        console.log({ error })
      })
  }

  restart() {
    this.toast = {
      message: 'All your trainers are free now!',
      title: 'Bye bye!',
      type: 'success',
      ic: {
        timeOut: 2500,
        closeButton: true,
      } as IndividualConfig,
    };
    this.cs.showToast(this.toast);

    localStorage.clear();
    // this.ngOnInit()
    setTimeout(function () {
      window.location.href = 'https://dartsleague.io/thehunt';
    }, 500);
  }

  //check if querystring fits userarray
  checkUser() {
    this.counter = 0
    let users = JSON.parse(localStorage.getItem("userList"));
    this.alreadySent = localStorage.getItem('status')
    this.started = localStorage.getItem('started')
    for (let i = 0; i < users.length; i++) {
      if (this.user === users[i].key) {
        if(users[i].status){
          //already caught
          this.toast = {
            message: 'You already caught ' + users[i].username + '!',
            title: 'Double-catch',
            type: 'info',
            ic: {
              timeOut: 2500,
              closeButton: true,
            } as IndividualConfig,
          };
          this.cs.showToast(this.toast);
        }
        else{
          //new caught
          users[i].status = true;
          localStorage.setItem('userList', JSON.stringify(users))
          localStorage.setItem('started', 'true')
          this.toast = {
            message: users[i].username + ' was caught! ',
            title: 'Gotcha!',
            type: 'success',
            ic: {
              timeOut: 2500,
              closeButton: true,
            } as IndividualConfig,
          };
          this.cs.showToast(this.toast);
        }
        users[i].recent = true;
        this.counter++

      } else if (users[i].status) {
        this.counter++
      }
    }
    this.players = users
    //check if completed
    this.checkStatus();
  }

  seeProfile(id) {
    let url = 'https://app.dartsleague.io/profile/' + id
    window.open(url, "_blank");
  }

  checkStatus() {
    if (localStorage.userList.indexOf(false) === -1) {
      //completed
      this.status = true;
    }
  }

}
