import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppsComponent } from './apps/apps.component';
import { HomeComponent } from './home/home.component';
import { PokemonComponent } from './pokemon/pokemon.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SupportComponent } from './support/support.component';

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "apps", component: AppsComponent },
  { path: "support", component: SupportComponent },
  { path: "thehunt", component: PokemonComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
