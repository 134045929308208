import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit {

  constructor(
    private router: Router,

  ) { }

  ngOnInit() {
  }

  open(target: string) {
    let url
    if (target === "ios") url = 'https://apps.apple.com/us/app/dartsleague/id1589828557'
    else if (target === "android") url = 'https://play.google.com/store/apps/details?id=dartsleague.io'
    else url = 'https://app.dartsleague.io/app/home'
    window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );

  }

}
